import "./landingSlider.css";

//import swiper realte requirement.
import "swiper/css";
import "swiper/css/navigation";

import BorderStyle from "../../images/white_border.svg";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';


function LandingSlider() {
  // const scroll = (elementName) => {
  //   const section = document.querySelector(elementName);
  //   section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  // };
  const content = [
    {
      title: "GUARANTEE 15 YEARS",
      description:
        "Patented products that have no analogues in the world",
      button: "Product",
      bgCss: "bg-three",
    },
    {
      title: "TRUST GAINED WITH TIME",
      description:
        "Working with business from Canada, Australia, Poland and Germany",
      button: "Learn More",
      bgCss: "bg-two",
    },
    {
      title: "INNOVATIVE WORK!",
      description:
        "We develop our own exclusive lines of WPC products for customers and bring it to the market.",
      button: "Contact Us",
      bgCss: "bg-one",
    }
  ];
  return (
    <div className="landingSlider-wrapper">
      <div className="border-container">
        <img src={BorderStyle} alt="" />
      </div>
      <Slider className="slider-wrapper" autoplay={10000}>
        {
          content.map((item, index) => (<div key={index} className={`slider-content ${item.bgCss} `}>
            <div className="inner">
              <h1>{item.title}</h1>
              <p>{item.description}</p>
              <button className="custom-hover" ><a href={item.button === "Contact Us" ?"/contact-us" : item.button === "Learn More" ? "/Jimbaran" :"#product-display"}>{item.button}</a></button>
            </div>
          </div>))
        }
      </Slider>
    </div>
  );
}

export default LandingSlider;
