import "./landing.css";
import NavbarComponent from "../../components/navbar/navbar";
import LandingSlider from "../../components/landing-slider/landingSlider";
import React, { useState, useEffect, useRef } from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination } from 'swiper';
import itemIconOne from "../../images/WELCOME_PAGE/block2/02-58x58-1.webp";
import itemIconTwo from "../../images/WELCOME_PAGE/block2/03-58x58-1.webp";
import itemIconThree from "../../images/WELCOME_PAGE/block2/05-58x58-1.webp";
import itemIconFour from "../../images/WELCOME_PAGE/block2/06-58x58-1.webp";
import itemIconFive from "../../images/WELCOME_PAGE/block2/glavnaja-dpk-terrasa-i-zabor--768x576.webp";
// import itemIconSix from "../../images/WELCOME_PAGE/block2/ikonka-15-let-glavnaja-e1653529595547.webp";
import FooterComponent from "../../components/footer/footer";
import bgOne from "../../images/bg_four.webp";
import bgTwo from "../../images/bg_two.jpg";


import silderOne from "../../images/WELCOME_PAGE/block4/1.jpg";
import silderTwo from "../../images/WELCOME_PAGE/block4/2.jpg";
import silderThree from "../../images/WELCOME_PAGE/block4/3.jpg";
import silderFour from "../../images/WELCOME_PAGE/block4/4.jpg";
import silderFive from "../../images/WELCOME_PAGE/block4/5.jpg";
import silderSix from "../../images/WELCOME_PAGE/block4/6.jpg";

import { isMobile } from 'react-device-detect';

function Landing() {
  document.title = "Polivan | Welcome"
  const [numSwiper, setNumSwiper] = useState(null);


  const [picSwiper, setPicSwiper] = useState(null);

  let currentPage = 0;

  const slideTo = (index) => {
    numSwiper.slideTo(index);
    picSwiper.slideTo(index);
  };


  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  const [scrollY, setScrollY] = useState(0);
  const item_One = useRef(null);
  const item_Two = useRef(null);
  const item_Three = useRef(null);
  const item_Four = useRef(null);
  const item_Five = useRef(null);
  const item_Six = useRef(null);

  const display_bg = useRef(null);

  const display_item_One = useRef(null);
  const display_item_Two = useRef(null);
  const display_item_Three = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      if (window.scrollY > 300 && window.scrollY < 600) {
        item_One.current.classList.add("move-down");
        item_Two.current.classList.add("move-up");
        item_Three.current.classList.add("move-down");
        item_Four.current.classList.add("move-up");
        item_Five.current.classList.add("move-down");

        item_One.current.classList.remove("move-down-more");
        item_Two.current.classList.remove("move-up-more");
        item_Three.current.classList.remove("move-down-more");
        item_Four.current.classList.remove("move-up-more");
        item_Five.current.classList.remove("move-down-more");

      } else if (window.scrollY > 600) {
        item_One.current.classList.add("move-down-more");
        item_Two.current.classList.add("move-up-more");
        item_Three.current.classList.add("move-down-more");
        item_Four.current.classList.add("move-up-more");
        item_Five.current.classList.add("move-down-more");


        item_One.current.classList.remove("move-down");
        item_Two.current.classList.remove("move-up");
        item_Three.current.classList.remove("move-down");
        item_Four.current.classList.remove("move-up");
        item_Five.current.classList.remove("move-down");

      } else {
        item_One.current.classList.remove("move-down");
        item_Two.current.classList.remove("move-up");
        item_Three.current.classList.remove("move-down");
        item_Four.current.classList.remove("move-up");
        item_Five.current.classList.remove("move-down");

        item_One.current.classList.remove("move-down-more");
        item_Two.current.classList.remove("move-up-more");
        item_Three.current.classList.remove("move-down-more");
        item_Four.current.classList.remove("move-up-more");
        item_Five.current.classList.remove("move-down-more");

      }
    };
    if (!isMobile && getWindowDimensions().width > 1000) {
      handleScroll();
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (!isMobile && getWindowDimensions().width > 1300) window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <NavbarComponent></NavbarComponent>
      {/* home page slider */}
      <LandingSlider></LandingSlider>

      {/* feature sections */}
      <div className="feature-section">
        <div className="inner-container">
          <div className="feature-item" ref={item_One}>
            <div className="item-icon">
              <img className="my-img" src={itemIconFive} alt="icon" />
            </div>
            <div className="item-txt">Durability</div>
            <div className="s-border"></div>
            <div className="item-content">
              Service life 25 years
            </div>
          </div>
          <div className="feature-item" ref={item_Two}>
            <div className="item-icon">
              <img className="my-img" src={itemIconOne} alt="icon" />
            </div>
            <div className="item-txt">Eco-Friendly</div>
            <div className="s-border"></div>
            <div className="item-content">
              70% natural raw materials
            </div>
          </div>
          <div className="feature-item" ref={item_Three}>
            <div className="item-icon">
              <img className="my-img" src={itemIconTwo} alt="icon" />
            </div>
            <div className="item-txt">No maintenance needed</div>
            <div className="s-border"></div>
            <div className="item-content">
              No additional processing required
            </div>
          </div>
          <div className="feature-item" ref={item_Four}>
            <div className="item-icon">
              <img className="my-img" src={itemIconThree} alt="icon" />
            </div>
            <div className="item-txt">Protection</div>
            <div className="s-border"></div>
            <div className="item-content">
              Materials are not subject to destruction from insects, fungus and ultraviolet radiation
            </div>
          </div>
          <div className="feature-item" ref={item_Five}>
            <div className="item-icon">
              <img className="my-img" src={itemIconFour} alt="icon" />
            </div>
            <div className="item-txt">Appearance</div>
            <div className="s-border"></div>
            <div className="item-content">
              Beautiful and unique 3D wood texture
            </div>
          </div>
        </div>
      </div>
      {!isMobile && <div id="product-display" ref={display_bg} className="display-section new-bg-two">
        <div className="display-section-dark-cover"></div>
        <div
          className="display-item"
          onMouseEnter={() => {
            display_bg.current.className = "display-section new-bg-two";
          }}
        >
          <div className="display-item-txt">
          DENPASAR Collection
            </div>
          <div ref={display_item_One} className="display-wrapper ghost">
            <div className="display-content">
            A collection of WPC profiles, which includes all the necessary profiles and accessories for organizing outdoor recreation areas.The texture of the DENPASAR terrace system has a deep 3D wood texture pattern. All profiles of the collection are developed by POLIVAN and are unique in the market. The thickness of the WPC walls and the density of the material have high quality indicators.
            </div>
            <div className="display-btn"><a href="/Denpasar">EXPLORE COLLECTION</a></div>
          </div>
        </div>
        <div
          className="display-item"
          onMouseEnter={() => {
            display_bg.current.className = "display-section new-block-bg";

          }}
        >
          <div className="display-item-txt">JIMBARAN Collection</div>
          <div ref={display_item_Two} className="display-wrapper ghost">
            <div className="display-content">
            Combined fence system made of aluminum and WPC. Modular fences with double-sided wood texture are packed in boxes. Both sides of the fence are facial. It is resistant to changing climatic conditions and the effects of insects. Assembling the system in 3 stages ensures quick and easy installation. A strong aluminum system and WPC fence boards guarantee the strength and durability of the fence. No additional maintenance is required.
            </div>
            <div className="display-btn"><a href="/Jimbaran">EXPLORE COLLECTION</a></div>
          </div>
        </div>
      </div>}
      {
        isMobile && <div id="product-display" className="mobile-display-section">
          <div>
            <div className="mds-title">
              COLLECTION DENPASAR
            </div>
            <div className="mds-img">
              <img src={bgOne} alt="" />
            </div>
            <div className="mds-btn banner-btn">
              GO to collection
            </div>
            <div className="mds-content">
              The DENPASAR collection is a medium—priced product, it combines a terrace system in 3D execution and a fencing system in the usual classical wood texture. DENPASAR is the reinforced walls of each WPC profile, developed by the engineers of the POLIVAN INC company, and has no analogues in its shape and thickness.
            </div>
          </div>
          <div>
            <div className="mds-title">
              COLLECTION JIMBARAN
            </div>
            <div className="mds-img">
              <img src={bgTwo} alt="" />
            </div>
            <div className="mds-btn banner-btn">
              GO to collection
            </div>
            <div className="mds-content">
              A collection of WPC products that technically and visually do not have
              analogues all over the world. Profiles with increased wall thickness.
              Unique deep 3D wood texture. Production is running on
              exclusive equipment in the city of Shanghai. Quality assurance - 15</div>
          </div>
        </div>
      }

      <h1 className="company-title-name">Polivan Inc – the company of the future</h1>
      <p className="company-desc">
        The company's mission is to form a culture of WPC consumption and set trends in the development of the wood plastics market. We offer comprehensive solutions for products made of wood-polymer composite (WPC). All collections are own developments of the Polivan Inc. Our products are unique and have no analogues on the market. All rights are registered, and utility models are patented. We offer a high quality product on exclusive rights to our wholesale partners. The end user receives a complete ready-made solution from our authorized dealers, qualified installation, service support and high quality assurance terms.
      </p>
      <div className="banner-btn-wrapper">
        <div className="banner-btn w-100"><a href="/contact-us" >Contact Us</a> </div>
      </div>
      <div className="swiper-section">
        <div className="num-swiper">

        </div>
        <div className="title-swiper">

          <div className="">
            <div className="slider-txt-style">
              Wide-range solutions!
            </div>
          </div>

        </div>

        <div className="image-swiper">
          <Swiper className="mySwiper mySwiper-imgSP" modules={[Autoplay, Pagination]} autoplay={{ delay: 2000 }} onSwiper={setPicSwiper} onSlideChange={
            (swiperCore) => {
              const { activeIndex, snapIndex, previousIndex, realIndex } = swiperCore;
              currentPage = activeIndex;
              slideTo(activeIndex)
            }
          }>
            <SwiperSlide >
              {" "}
              <img className="img-swiper" src={silderOne} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="img-swiper" src={silderTwo} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="img-swiper" src={silderThree} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="img-swiper" src={silderFour} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="img-swiper" src={silderFive} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <img className="img-swiper" src={silderSix} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>

      </div>

      <FooterComponent></FooterComponent>

    </>
  );
}

export default Landing;
