import React from "react";
import FooterComponent from "../../components/footer/footer";
import NavbarComponent from "../../components/navbar/navbar";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react"; import { useNavigate } from "react-router-dom";
import ItemPrice from '../../components/itemPrice/itemPrice'
import item1 from '../../images/collections/denpasar/2/black.webp'
import item2 from '../../images/collections/denpasar/7/default.webp'


import "./style.css"


const Calculator = () => {
    const PRICE_DENPASAR = 7.7 + 0.56;
    const navigate = useNavigate();
    const [priceDensapar, setPriceDensapar] = useState(0)

    const [squareFeetDenpasar, setFeetDenpasar] = useState(0)

    const [total, setTotal] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handlePriceChangeDenpasar = (e) => {

        if (e.target.value !== "") {
            setPriceDensapar(Number(e.target.value) * PRICE_DENPASAR);
            setTotal(Number(e.target.value) * PRICE_DENPASAR)
            setFeetDenpasar(Number(e.target.value))
        } else {
            setPriceDensapar(0)
            setFeetDenpasar(0)
        }

    }

    const navIntoItem = (name, id) => {
        navigate(`/${name}/${id}`)
    }

    return (
        <div>
            <NavbarComponent></NavbarComponent>
            <div className="title-block">
                Price Calculator
            </div>
            <div style={{ height: "30vh !important" }} className="width80centered2 height-65 d-flex justify-content-center">
                <Col xs={12} lg={8}>
                    <Card >
                        <Container className="bordered mb-3">
                            <Row>
                                <Col className="mb-3" xs={12}>
                                    <Form className="padding10 padding-botton">
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <h2 className="mb-3">Denpasar (decking)</h2>
                                            <Row className="my-row mb-3">
                                                <Col xs={7}>
                                                    <Form.Label>Sqr Feet</Form.Label>
                                                    <Form.Control onChange={handlePriceChangeDenpasar} type="number" min="0" placeholder="Enter square feet" />
                                                </Col>
                                                <Col xs={5}>
                                                    <Form.Label>Total Denpasar</Form.Label>
                                                    <h3> = {parseFloat(priceDensapar.toString()).toFixed(2)}$</h3>
                                                </Col>

                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="mb-3" xs={12} style={{ textAlign: 'center' }}>
                                    <h3>Total:{parseFloat(total.toString()).toFixed(2)}$</h3>
                                </Col>
                                <Col className="mb-3" xs={12} style={{ textAlign: 'center' }}>
                                    <button className="custom-hover" ><a href={"/contact-us"}>Contact Us</a></button>
                                </Col>
                                <Col className="mb-3" xs={12} >
                                    <p className="mt-3">Tax is not included in the above price. The price can vary depends on your order. Please, contact us for more information.</p>

                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </div>
            <div className="title-block">
                Estimated items
            </div>
            <Col xs={12}>
                <div style={{ textAlign: "center" }} className="mb-3 ">
                    <h3>Denpasar items:</h3>
                    <div className="collection-content-row mb-3">
                        {squareFeetDenpasar !== 0 ?
                            <div className="collection-right">

                                <ItemPrice onClick={() => navIntoItem('Denpasar', 2)} img={item1} quantity={parseFloat((squareFeetDenpasar * 0.12).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetDenpasar * 7.75).toString()).toFixed(2)}></ItemPrice>
                                <ItemPrice onClick={() => navIntoItem('Denpasar', 7)} img={item2} quantity={parseFloat((squareFeetDenpasar * 2).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetDenpasar * 0.56).toString()).toFixed(2)}></ItemPrice>


                            </div> : <p className="collection-right mb-3">Denpasar cart is empty</p>}
                    </div>
                    
                </div>
            </Col>

            <FooterComponent></FooterComponent>
        </div>
    );
}


export default Calculator;