
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ContactUs from './pages/contact-us/contact-us'
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Landing from "./pages/landing/landing";
import Collection from "./pages/collection/collection";
import ItemDetail from "./pages/itemDetail/itemDetail";
import Calculator from './pages/calculator/calculator';
import Shipping from './pages/shipping/shipping';
import CalculatorSmoothie from './pages/calculator/calculatorSmoothie';
import CalculatorWoodys from './pages/calculator/calculatorWoodys';
import CalculatorIntense from './pages/calculator/calculatorIntense';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/price-calculator-denpasar" element={<Calculator />} />
      <Route path="/price-calculator-sandsmoothie" element={<CalculatorSmoothie />} />
      <Route path="/price-calculator-woodys" element={<CalculatorWoodys />} />
      <Route path="/price-calculator-woodysintense" element={<CalculatorIntense/>} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/:name" element={<Collection />} />
      <Route path="/delivery" element={<Shipping/>} />
      <Route path="/:colName/:usageId" element={<ItemDetail />} />
    </Routes>
  );
}

export default App;
