import React from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TypeAnimation } from "react-type-animation";

import { useState, useEffect, useRef } from "react";
import NavbarComponent from '../../components/navbar/navbar'
import FooterComponent from "../../components/footer/footer";
import './index.css'

import emailjs from '@emailjs/browser';
import wood from '../../images/glavnaja-dpk-terrasa-i-zabor--768x576.webp'
import toast, { Toaster } from 'react-hot-toast';

import validator from "email-validator";
const ContactUs = () => {
    document.title = "Polivan | Contact Us"
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const descRef = useRef(null)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const onSubmit = () => {
        if (validator.validate(emailRef.current.value)) {
            if (nameRef.current.value) {
                emailjs.send('service_6700zq5', 'template_vwbgitr', { client_name: nameRef.current.value, client_email: emailRef.current.value, client_desc: descRef.current.value }, 'WqhoJycVy1C776NR9')
                    .then((result) => {
                        nameRef.current.value = ''
                        emailRef.current.value = ''
                        descRef.current.value = ''
                        toast.success('Email Sent Success!')
                    }, (error) => {
                        toast.error('Server error, we will fix it soon! Sorry.')
                    });
            } else {
                nameRef.current.focus()
                toast.error('name is required...')
            }
        } else {
            emailRef.current.focus()
            toast.error('Please enter a valid E-mail adress!')
        }


    }

    return (
        <>
            <NavbarComponent></NavbarComponent>
            <div className="width80centered2    ">
                <Container>
                    <Row className="">
                        <Col className="mb-3" xs={12}>
                            <h1>Contact Us</h1>
                        </Col>
                        <Col className="mb-2" xs={12} md={5}>
                            <Card>
                                <Form className="padding10">

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control ref={nameRef} type="name" placeholder="Enter name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Contact email address</Form.Label>
                                        <Form.Control ref={emailRef} type="email" placeholder="Enter email" />
                                        <Form.Text className="text-muted">
                                            We'll never share your email with anyone else.
                                        </Form.Text>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control ref={descRef} type="description" placeholder="Enter description" />
                                        <Form.Text className="text-muted">
                                            Briefly describe your request.
                                        </Form.Text>
                                    </Form.Group>
                                    <Button variant="primary" onClick={onSubmit}>
                                        Submit
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                        <Col className="ml-3 mt-my" xs={12} md={6}>
                            <p className="extraBigText">Please, fill the form.</p>
                            {/* <h1>We will contact you</h1> */}
                            <TypeAnimation
                                sequence={['We will contact you', 5000, '', 1000]}
                                speed={50} // Must be in range between 1 and 99!
                                wrapper="h1"
                                repeat={Infinity}
                            />
                            <h1><span className="darkblue">10:00am - 8:00pm</span></h1>
                            <h3><span className="opacity-grey">Monday to Friday</span></h3>
                            <br></br>
                            <h1>Also you can contact us via:</h1>
                            <br />

                            <ul id="contact-list">
                                <li className="ml-1"><a href="mailto:info@polivan.ca"><h2>Email</h2></a></li>
                                <li><h2> | </h2></li>
                                <li className="ml-1"><a href="tel:416-875-4802"><h2>Call</h2></a></li>
                                <li><h2> | </h2></li>
                                <li className="ml-1"><a href="https://wa.me/14168754802"><h2>WhatsUp</h2></a></li>
                            </ul>
                        </Col>

                    </Row>
                    <Row className="mt-ext">
                        <Col className="mb-5" xs={12} md={4}>
                            <img className="" src={wood} alt="wood" />
                        </Col>
                        <Col className="mt-1" xs={12} md={6}>
                            <h3 className="text-h3">Best decking solutions!</h3>
                            <p className="text-p">Our company manufactures and wholesales integrated solutions from wood-polymer composite (WPC) for a comfortable stay in private and public areas</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <FooterComponent></FooterComponent>

        </>
    );
}

export default ContactUs;