import React from "react";
import FooterComponent from "../../components/footer/footer";
import NavbarComponent from "../../components/navbar/navbar";
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState, useEffect } from "react"; import { useNavigate } from "react-router-dom";
import ItemPrice from '../../components/itemPrice/itemPrice'


import item3 from '../../images/collections/intense/fence.png'
import item4 from '../../images/collections/intense/wood.png'
import middle from '../../images/collections/intense/middle.png'
import item5 from '../../images/collections/intense/newbottombar.png'
import item6 from '../../images/collections/intense/newtopbar.png'

import item7 from '../../images/collections/jimbaran/14/default.jpg'
import item8 from '../../images/collections/jimbaran/17/default.jpg'

import item9 from '../../images/collections/jimbaran/22/black.jpg'
import item10 from '../../images/collections/jimbaran/21/black.png'


import "./style.css"


const CalculatorIntense = () => {

    const PRICE_JIMBARAN = 671.12//1157.51;
    const navigate = useNavigate();

    const [priceJimbaran, setPriceJimbaran] = useState(0)
    const [squareFeetJimbaran, setFeetJimbaran] = useState(0)
    const [total, setTotal] = useState(0);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  
    const handlePriceChangeJimbaran = (e) => {

        if (e.target.value !== "") {
            setPriceJimbaran(Number(e.target.value) * PRICE_JIMBARAN);
            setTotal(Number(e.target.value) * PRICE_JIMBARAN)
            setFeetJimbaran(Number(e.target.value))
        } else {
            setPriceJimbaran(0)
            setFeetJimbaran(0)
        }

    }

    const navIntoItem = (name, id) => {
        navigate(`/${name}/${id}`)
    }

    return (
        <div>
            <NavbarComponent></NavbarComponent>
            <div className="title-block">
                Price Calculator
            </div>
            <div style={{ height: "30vh !important" }} className="width80centered2 height-65 d-flex justify-content-center">
                <Col xs={12} lg={8}>
                    <Card >
                        <Container className="bordered mb-3">
                            <Row>
                                <Col className="mb-3" xs={12}>
                                    <Form className="padding10 padding-botton">
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <h2>Woodys Intense (fences)</h2>
                                            <Row className="my-row">
                                                <Col xs={7}>
                                                    <Form.Label>Number of sets(1750x1900)</Form.Label>
                                                    <Form.Control type="number" min="0" onChange={handlePriceChangeJimbaran} placeholder="Enter number of sets" />
                                                </Col>
                                                <Col xs={5}>
                                                    <Form.Label>Total Woodys Intense</Form.Label>
                                                    <h3> = {parseFloat(priceJimbaran.toString()).toFixed(2)}$</h3>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col className="mb-3" xs={12} style={{ textAlign: 'center' }}>
                                    <h3>Total:{parseFloat(total.toString()).toFixed(2)}$</h3>
                                </Col>
                                <Col className="mb-3" xs={12} style={{ textAlign: 'center' }}>
                                    <button className="custom-hover" ><a href={"/contact-us"}>Contact Us</a></button>
                                </Col>
                                <Col className="mb-3" xs={12} >
                                    <p className="mt-3">Tax is not included in the above price. The price can vary depends on your order. Please, contact us for more information.</p>

                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Col>
            </div>
            <div className="title-block">
                Estimated items
            </div>
            <Col xs={12}>
                <div style={{ textAlign: "center" }} className="mb-3 ">
                    <h3 className="mt-3">Woodys Intense items:</h3>
                    <div className="collection-content-row mb-3">
                        {squareFeetJimbaran !== 0 ?
                            <div>
                                <div className="collection-right">
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 1)} img={item3} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={'Assembles with peaces specified below'} ></ItemPrice>
                                </div>
                                <div className="collection-right">


                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 23)} img={item4} quantity={parseFloat((squareFeetJimbaran * 8).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 36.7*8).toString()).toFixed(2) + '$'} ></ItemPrice>
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 30)} img={middle} quantity={parseFloat((squareFeetJimbaran * 7).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 17.68*7).toString()).toFixed(2) + '$'} ></ItemPrice>
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 19)} img={item5} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 22.90).toString()).toFixed(2) + '$'} ></ItemPrice>

                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 20)} img={item6} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 28.60).toString()).toFixed(2) + '$'} ></ItemPrice>
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 14)} img={item7} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 129.13).toString()).toFixed(2) + '$'} ></ItemPrice>
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 17)} img={item8} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 64.35).toString()).toFixed(2) + '$'} ></ItemPrice>

                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 22)} img={item9} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 7.88).toString()).toFixed(2) + '$'} ></ItemPrice>
                                    <ItemPrice onClick={() => navIntoItem('WoodysIntense', 21)} img={item10} quantity={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2)} totalPrice={parseFloat((squareFeetJimbaran * 1).toString()).toFixed(2) + '$'} ></ItemPrice>
                                </div>
                            </div> : <p className="collection-right mb-3">Woodys Intense cart is empty</p>}
                    </div>
                </div>
            </Col>

            <FooterComponent></FooterComponent>
        </div>
    );
}


export default CalculatorIntense;