import './usagesRow.css'
import usagesOne from '../../images/collections/usages/001-bench.webp';
import usagesTwo from '../../images/collections/usages/002-pool.webp';
import usagesThree from '../../images/collections/usages/003-facade.webp';
import usagesFour from '../../images/collections/usages/004-outdoor-cafe.webp';
import usageFive from '../../images/collections/usages/005-pier.webp';
import usagesSix from '../../images/collections/usages/006-house.webp';
function UsagesRow() {

  return (
    <div className="usages-wrapper">
      <div className="usage-item">
        <img src={usagesOne} alt="" />
        <div><p>Park</p></div>
      </div>
      <div className="usage-item">
        <img src={usagesTwo} alt="" />
        <div>Swimming pool</div>
      </div>
      <div className="usage-item">
        <img src={usagesThree} alt="" />
        <div>Balcony</div>
      </div>
      <div className="usage-item">
        <img src={usagesFour} alt="" />
        <div>Cafe</div>
      </div>
      <div className="usage-item">
        <img src={usageFive} alt="" />
        <div>Pier</div>
      </div>
      <div className="usage-item">
        <img src={usagesSix} alt="" />
        <div>House</div>
      </div>
    </div>
  );
}

export default UsagesRow;
