import "./itemDetail.css";
import Navbar from "../../components/navbar/navbar";
import data from "../../data/collections.json";
import ColorPicker from "../../components/colorPicker/colorPicker";
import UsagesRow from "../../components/usagesRow/usagesRow";
import { useParams } from "react-router-dom";
import FooterComponent from "../../components/footer/footer";
import { isMobile } from 'react-device-detect';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
function ItemDetail() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const BASE_URL = "http://localhost:3000";
    const FIREBASE_URL = "https://slava-viola-2022-797c9.web.app/"
    const { colName, usageId } = useParams();
    const [colorPicker, setColorPicker] = useState(0);
    const navigate = useNavigate();
    let itemImages = [];
    const fetchUsageDetail = () => {
        let collection = {};
        for (let i = 0; i < data.collections.length; i++) {
            if (colName.toLowerCase() === data.collections[i].collection_name.toLowerCase()) {
                collection = data.collections[i];
                break;
            }
        }

        const items = collection.usages[0].items;
        for (let i = 0; i < items.length; i++) {
            if (usageId == items[i].item_id) {
                return items[i];
            }
        }
    }


    const item = fetchUsageDetail();

    const getMultiplayer = () => {
        if (item.img_colors.length > 1) {
            if (colorPicker === 2) {
                return 1.15
            }
            if (colorPicker === 4) {
                return 1.10
            }
            if (colorPicker === 5) {
                return 1.05
            }
            return 1;
        } else {
            return 1;
        }
    }

    return (
        <>
            <Navbar></Navbar>
            <div className="item-short-title">{item.item_short_name}</div>
            <div className="usage-group-wrapper">
                <div className="usage-content">
                    <div className="row-first">
                        <div className="first-left">
                            <img src={`${FIREBASE_URL}${item.img_colors[colorPicker]}`} alt="" />
                        </div>
                        {isMobile && <div className="first-right">
                            <ColorPicker selectedIndex={colorPicker} onColorClick={setColorPicker} />
                        </div>}
                        {!isMobile && <div className="first-right">
                            <div className="nums-detail">
                                {item.description.garanty && <div className="detail-item">{`Warranty: ${item.description.garanty}`}</div>}
                                {item.description.made_company && <div className="detail-item">{`Manufacturer: ${item.description.made_company}`}</div>}
                                {item.description.size && <div className="detail-item">{`Size: ${item.description.size}`}</div>}
                                {item.description.weight_square_meter && <div className="detail-item">{`Weight: ${item.description.weight_square_meter}`}</div>}
                                {item.price && <div className="detail-item">{`Price: ${parseFloat((item.price * getMultiplayer()).toString()).toFixed(2)}$/pc`}</div>}
                                {/* {item.description.parts && <div className="detail-item">{`Parts: ${item.description.parts}`}</div>} */}
                            </div>

                        </div>}
                    </div>
                    {item.img_colors.length > 1 && <div className="color-picker-row">
                        {!isMobile && <ColorPicker selectedIndex={colorPicker} onColorClick={setColorPicker} />}
                        {isMobile && <div className="nums-detail">
                            <div className="detail-item">{`Garanty: ${item.description.garanty}`}</div>
                            <div className="detail-item">{`Productor: ${item.description.made_company}`}</div>
                            <div className="detail-item">{`From: ${item.description.made_country}`}</div>
                            <div className="detail-item">{`Size: ${item.description.size}`}</div>
                            <div className="detail-item">{`Weight: ${item.description.weigh}`}</div>
                        </div>}
                    </div>}
                    <div className="full-name-row">
                        {item.item_full_name}
                    </div>
                    <div className="row-second">
                        <div className="second-left content-style padding-around">
                            {item.description_long}
                        </div>
                        <div className="second-right">

                            <UsagesRow></UsagesRow>



                        </div>
                    </div>
                    <div className="button-wrapper" > <button onClick={() => { navigate('/contact-us') }} className="hover-collection">Order Now</button></div>
                </div>

            </div>

            <FooterComponent></FooterComponent>
        </>
    );
}

export default ItemDetail;
