import "./navbar.css";
import logoLong from '../../images/LOGO.png';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const NavbarComponent = () => {
    return (
        <>
            <Navbar className="my-nav-dark" expand="lg">
                <Container className="my-nav-dark">
                    <Nav className="me-auto postion-top-nav">
                        <Nav.Link className="my-link-light group disabled" href="/">Mon-Fri: 10am-8pm</Nav.Link>
                        <Nav.Link className="my-link-light disabled">|</Nav.Link>

                        <Nav.Link className="my-link-light group green-hover" href="tel:416-875-4802"><i className=" bi bi-telephone green-hover"></i>(416)-875-4802</Nav.Link>
                        <Nav.Link className="my-link-light group green-hover" href="mailto:info@polivan.ca" ><i className="bi bi-envelope green-hover"></i> info@polivan.ca</Nav.Link>

                        <Nav.Link className="my-link-light disabled" >|</Nav.Link>

                        <ul className="list-inline" id="menu">
                            <li className="ml-3 green-hover"><Nav.Link className="my-link-light group green-hover" target="_blank" href="https://wa.me/14168754802"><i className="bi bi-whatsapp green-hover"></i></Nav.Link></li>

                            <li className="ml-3 green-hover"> <Nav.Link className="my-link-light group green-hover" target="_blank" href="https://www.instagram.com/polivan.ca/?igshid=YmMyMTA2M2Y%3D"><i className="bi bi-instagram green-hover"></i></Nav.Link></li>
                            <li className="ml-3 green-hover"><Nav.Link className="my-link-light group green-hover" target="_blank" href="https://www.facebook.com/"><i className="bi bi-facebook green-hover"></i></Nav.Link></li>
                        </ul>
                    </Nav>
                </Container>
            </Navbar>
            <Navbar bg="light" expand="lg">
                <Container className="my-nav">
                    <Navbar.Brand href="/">
                        <div className="logo">
                            <img src={logoLong} alt="logo" />
                        </div></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className=" marginLeft20">
                        <Nav className="me-auto">
                            <Nav.Link className="my-link hover-underline-animation" href="/">Welcome</Nav.Link>
                            <NavDropdown className="my-link hover-underline-animation " title="Price Calculator" id="basic-nav-dropdown">
                            <p className="ml-1">JIMBARAN FENCES</p>
                                <NavDropdown.Item className="dropDown" href="/price-calculator-sandsmoothie">Sand Smoothie</NavDropdown.Item>
                                <NavDropdown.Item className="dropDown" href="/price-calculator-woodys">Woodys</NavDropdown.Item>
                                <NavDropdown.Item className="dropDown" href="/price-calculator-woodysintense">Woodys Intense</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <p className="ml-1 mr-1">DENPASAR DECKING</p>
                                <NavDropdown.Item className="dropDown" href="/price-calculator-denpasar">Deck 3D</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className="my-link hover-underline-animation" href="/delivery">Delivery</Nav.Link>
                            <Nav.Link className="my-link hover-underline-animation" href="/contact-us">Contact Us</Nav.Link>
                            <NavDropdown className="my-link hover-underline-animation " title="Collections" id="basic-nav-dropdown">
                            <p className="ml-1 mr-1">JIMBARAN FENCES</p>
                                <NavDropdown.Item className="dropDown" href="/SandSmoothie">Sand Smoothie</NavDropdown.Item>
                                <NavDropdown.Item className="dropDown" href="/Woodys">Woodys</NavDropdown.Item>
                                <NavDropdown.Item className="dropDown" href="/WoodysIntense">Woodys Intense</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <p className="ml-1">DENPASAR DECKING</p>
                                <NavDropdown.Item className="dropDown" href="/Denpasar">Deck 3D</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar></>
    );
}

export default NavbarComponent;
