import React from 'react'

import logo from '../../images/LOGO.png';
import './footer.css'
import { useState, useEffect, useRef } from "react";

import toast, { Toaster } from 'react-hot-toast';
import emailjs from '@emailjs/browser';

import validator from "email-validator";
const FooterComponent = () => {

    const emailRef = useRef(null)
    const onSubscribe = () => {
        if (validator.validate(emailRef.current.value)) {
            if (emailRef.current.value) {
                emailjs.send('service_6700zq5', 'template_juxga2i', { client_email: emailRef.current.value }, 'WqhoJycVy1C776NR9')
                    .then((result) => {
                        emailRef.current.value = ''
                        toast.success('Subscribe Success!')
                    }, (error) => {
                        toast.error('ERROR: server problem! We fill fix it soon :)')
                    });
            }
        } else {
            emailRef.current.focus();
            toast.error('Please enter a valid E-mail adress!')
        }

    }
    return (

        <div className="container">
            <footer className="py-5">
                <div className="row">
                <div className="col-6 col-md-2 mb-3">
                        <h4>Price Calculator</h4>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/price-calculator-sandsmoothie" className="nav-link p-0 text-muted footer-link-fat"><h5>Sand Smoothie</h5></a></li>
                            <li className="nav-item mb-2"><a href="/price-calculator-woodys" className="nav-link p-0 text-muted footer-link-fat"><h5>Woodys</h5></a></li>
                            <li className="nav-item mb-2"><a href="/price-calculator-woodysintense" className="nav-link p-0 text-muted footer-link-fat"><h5>Woodys Intense</h5></a></li>
                            <li className="nav-item mb-2"><a href="/price-calculator-denpasar" className="nav-link p-0 text-muted footer-link-fat"><h5>Deck 3D</h5></a></li>
                        </ul>
                        
                    </div>

                    <div className="col-6 col-md-2 mb-3">
                        <h4>Our Collections</h4>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/SandSmoothie" className="nav-link p-0 text-muted footer-link-fat"><h5>Sand Smoothie</h5></a></li>
                            <li className="nav-item mb-2"><a href="/Woodys" className="nav-link p-0 text-muted footer-link-fat"><h5>Woodys</h5></a></li>
                            <li className="nav-item mb-2"><a href="/WoodysIntense" className="nav-link p-0 text-muted footer-link-fat"><h5>Woodys Intense</h5></a></li>
                            <li className="nav-item mb-2"><a href="/Denpasar" className="nav-link p-0 text-muted footer-link-fat"><h5>Deck 3D</h5></a></li>
                        </ul>
                    </div>

                    <div className="col-6 col-md-2 mb-3">
                        <a href="/contact-us" className="nav-link p-0 text-muted footer-link-fat"><h4>Contact Us</h4></a>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="mailto:info@polivan.ca" className="nav-link p-0 text-muted"><i className="bi bi-envelope-fill"></i> info@polivan.ca</a></li>
                            <li className="nav-item mb-2"><a href="tel:416-875-4802" className="nav-link p-0 text-muted"><i className="bi bi-telephone-fill"></i>(416)-875-4802</a></li>
                            <ul className="list-unstyled d-flex">
                                <li className="ms-3"><a className="link-dark" target="_blank" href="https://web.whatsapp.com/"><i id='footer' className="bi bi-whatsapp big-icon"></i></a></li>
                                <li className="ms-3"><a className="link-dark" target="_blank" href="https://www.instagram.com/polivan.ca/?igshid=YmMyMTA2M2Y%3D"><i id='footer' className="bi bi-instagram big-icon"></i></a></li>
                                <li className="ms-3"><a className="link-dark" target="_blank" href="https://www.facebook.com/"><i id='footer' className="bi bi-facebook big-icon"></i></a></li>
                            </ul>
                        </ul>
                    </div>
                    <div className="col-6 col-md-1 mb-3">
                        <img className='img-footer' src={logo} alt="" />
                    </div>

                    <div className="col-md-4 offset-md-1 mb-3">
                        <form>
                            <h5>Subscribe to our newsletter</h5>
                            <p>Monthly digest of what's new and exciting from us.</p>
                            <div className="d-flex flex-column flex-sm-row w-100-footer gap-2">
                                <label htmlFor="newsletter1" className="visually-hidden">Email address</label>
                                <input id="newsletter1" type="text" ref={emailRef} className="form-control" placeholder="Email address"></input>
                                <button className="btn btn-primary" type="button" onClick={onSubscribe}>Subscribe</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
                    <p>© 2022 Polivan, Inc. All rights reserved.</p>
                </div>
            </footer>
        </div>

    );
}

export default FooterComponent;