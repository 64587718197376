import "./usageDisplay.css";
import { useNavigate } from "react-router-dom";
function UsageDisplay({ itemData, name }) {
  const navigate = useNavigate();


  const BASE_URL = "http://localhost:3000";

  const FIREBASE_URL = "https://slava-viola-2022-797c9.web.app/"

  const navIntoItem = () => {
    navigate(`/${name}/${itemData.item_id}`)
  }
  const classCover = itemData.item_id === 1 ? 'usageCover top-height' : 'usageCover';
  return (
    <div className={`usage-item-container ${itemData.item_id === 1 ? ' wide' : ''}`} onClick={navIntoItem}>
      <div className={classCover}>
        <img className="small-padding" src={`${FIREBASE_URL}${itemData.item_pic_src}`} alt="item img" />
      </div>
      <div className="usageName">{itemData.item_short_name}</div>
    </div>
  );
}

export default UsageDisplay;


