import './colorPicker.css'
import colorBaig from '../../images/colors/color-baig-150x99.webp';
import colorBlack from '../../images/colors/color-black-150x98.webp';
import colorBrown from '../../images/colors/color-brown-150x96.webp';
import colorBrownII from '../../images/colors/color-brown-l-150x96.webp';
import colorGray from '../../images/colors/color-gray-150x98.webp';
import colorSand from '../../images/colors/color-sand-150x96.webp';
function ColorPicker({ selectedIndex, onColorClick }) {



  return (
    <div className="colorpicker-wrapper">
      <div className='center-100-color'><h4 >Choose color:</h4></div>
      <div className={`color-item red ${selectedIndex == 0 && "selected-color-item"}`} onClick={() => onColorClick(0)}>
        <img src={colorSand} alt="" />
      </div>
      <div className={`color-item red ${selectedIndex == 2 && "selected-color-item"}`} onClick={() => onColorClick(2)}>
        <img src={colorBaig} alt="" />
      </div>
      <div className={`color-item light-green ${selectedIndex == 3 && "selected-color-item"}`} onClick={() => onColorClick(3)}>
        <img src={colorBlack} alt="" />
      </div>
      <div className={`color-item light-gray ${selectedIndex == 5 && "selected-color-item"}`} onClick={() => onColorClick(5)}>
        <img src={colorBrownII} alt="" />
      </div>
      <div className={`color-item red ${selectedIndex == 1 && "selected-color-item"}`} onClick={() => onColorClick(1)}>
        <img src={colorBrown} alt="" />
      </div>
      <div className={`color-item red ${selectedIndex == 4 && "selected-color-item"}`} onClick={() => onColorClick(4)}>
        <img src={colorGray} alt="" />
      </div>

    </div>
  );
}

export default ColorPicker;
