import React from "react";



const ItemPrice = (props) => {
    return (
        <div onClick={props.onClick} className="usage-item-container">
            <div className="usageCover">
                <img className="small-padding" src={`${props.img}`} alt="ss" />
            </div>
            <div className="usageName">x{props.quantity} = {props.totalPrice}</div>
        </div>
    );
}


export default ItemPrice;