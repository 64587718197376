import "./collection.css";
import Navbar from "../../components/navbar/navbar";
import data from "../../data/collections.json";
import UsageDisplay from "../../components/usageDisplay/usageDisplay";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import FooterComponent from "../../components/footer/footer";
function Collection() {
  const { name } = useParams();

  document.title = `Polivan | Collection ${name}`
  const fetchCollection = (collectionName) => {
    for (let i = 0; i < data.collections.length; i++) {
      if (collectionName.toLowerCase() === data.collections[i].collection_name.toLowerCase()) {
        return data.collections[i];
      }
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  const collectionData = fetchCollection(name);
  return (
    <>
      <Navbar></Navbar>
      <div className="title-block">
        {collectionData.collection_name_show} {collectionData.purpose}
      </div>
      <div className="collection-content-row">
        <div className="collection-left">
          {collectionData.description}
        </div>
        <div className="collection-right">
          {collectionData.usages[0].items.map((item) => {
            return (
              <UsageDisplay itemData={item} name={name} />
            )
          })}
        </div>
      </div>

      <FooterComponent></FooterComponent>

    </>
  );
}

export default Collection;
